<template>
  <section class="advertising_content">
    <div class="container">
      <div class="advertising_title animate__animated animate__slow">
        <h2>{{ $t('advertising_title.title') }}</h2>
      </div>
      <div class="animate__animated animate__bounceInUp">
        <div class="advertising_blocs animate__animated animate__fadeIn animate__slow">
          <div class="advertising_bloc_wrapper animate__animated animate__fast"
               :class="i%2 === 0 ? 'rgt' : 'lft'"
               v-for="(item, i) in this.$i18n.messages[this.$i18n.locale].advertising_content"
               :key="'advertising_content-'+i">
            <div class="advertising_bloc">
              <div class="advertising_bloc_img_wrapper">
                <img :src="item.img" alt="" class=" animate__animated" draggable="false">
              </div>
              <div class="advertising_bloc_text_wrapper">
                <h3>{{ item.title }}</h3>
                <p>{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HandleScrollMixin from "@/mixins/HandleScrollMixin";

export default {
  mixins: [HandleScrollMixin],
  methods: {
    onScroll: function () {
      this.handleScroll( '.advertising_bloc_wrapper.rgt', 'animate__fadeInLeft', 60, false);
      this.handleScroll( '.advertising_bloc_wrapper.lft', 'animate__fadeInRight', 60, false);
      this.handleScroll( '.advertising_title', 'animate__fadeIn', 200, false)
    },
  },
  created () {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>

$imgSize: 10vw;

.flip-list-move {
  transition: transform 1s;
}

.advertising_bloc_wrapper{
  opacity: 0;
  &.animate__rotateInUpLeft, &.animate__rotateInUpRight {
    opacity: 1;
  }
}

.advertising_content {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: var(--bg2);
  overflow: hidden;
}

.advertising_title {
  display: flex;
  justify-content: center;
  opacity: 0;
  &.animate__fadeIn {
    opacity: 1;
  }

  h2 {
    font-family: var(--font_head);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color5);
  }
}

.advertising_blocs {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 5vmin auto 0;
  user-select: none;
}

.advertising_bloc_wrapper {
  width: 38vw;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.advertising_bloc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 28px -23px var(--color4), 0 0 1px -0.5px var(--color3);
  border-radius: 1rem;
  padding: 1.5rem;
  margin: 1rem;
  width: 100%;
  transition: transform ease-out 300ms;
  background-color: rgba(255, 255, 255, 0.63);
  text-align: left;

  &:hover {
    transform: scale(1.05);
    transition: transform ease-in 200ms;
    img {
      animation: tada 1s ease;
    }
  }

  img {
    width: $imgSize;
    height: $imgSize;
  }

  .advertising_bloc_img_wrapper {
    width: 40%;
    text-align: center;
  }

  .advertising_bloc_text_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
  }

  h3 {
    font-family: var(--font_head);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color5);
    margin-top: 1rem;
    max-width: 90%;
  }

  p {
    font-family: var(--font_texts);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.1em;
    color: var(--texts_color);
    margin-top: 1rem;
    max-width: 90%;
  }
}


// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
  h3 {
    font-size: 1rem;
  }

  p {
    font-size: 0.8rem;
    max-width: 95%;
  }

  .advertising_bloc_wrapper {
    width: 41vw;
  }

  .advertising_bloc img {
    width: 15vmin;
    height: 15vmin;
  }
}



// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .advertising_bloc img {
    width: 12vmin;
    height: 12vmin;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .advertising_bloc {
    flex-direction: column;
    .advertising_bloc_img_wrapper {
      text-align: center;
    }
    .advertising_bloc_text_wrapper {
      width: 100%;
      height: 100%;
      text-align: center;
      align-items: center;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.3rem;
    max-width: 95%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .advertising_bloc_wrapper {
    width: 80vw;
  }

  .advertising_bloc {
    img {
      width: 15vmax;
      height: 15vmax;
    }

    .advertising_bloc_img_wrapper {
      width: 30%;
      text-align: center;
    }

    .advertising_bloc_text_wrapper {
      width: 70%;
      & > * {
        text-align: center;
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .advertising_bloc {
    flex-direction: column;
    justify-content: start;
    margin-left: 0;
    margin-right: 0;

    img {
      width: 25vmin;
      height: 25vmin;
    }

    .advertising_bloc_img_wrapper {
      width: 100%;
    }

    .advertising_bloc_text_wrapper {
      width: 100%;
      align-items: center;

      & > * {
        text-align: center;
      }
    }
  }
}

</style>