<template>
  <div class="home">
    <Header head_text="service_page" content_text="service_content_text" page="service_page">
      <template v-slot:animation>
        <Animation/>
      </template>
    </Header>
    <WorkingProcess/>
    <Advertising />
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header_.vue'
import Advertising from '@/components/service_component/Advertising.vue';
import Footer from '@/components/Footer.vue';
import Animation from "@/components/service_component/Animation";
import WorkingProcess from "@/components/service_component/WorkingProcess_";

export default {
  name: 'Home',
  components: {
    WorkingProcess,
    Animation,
    Header,
    Map,
    Advertising,
    Footer
  },
  data () {
    return{
     
    }
  }
}
</script>
<style scoped>

</style>
