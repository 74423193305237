<template>
  <div id="container">
    <div class="con">
      <div class="blocs">
        <div class="bloc1 move_up_down">
          <img class="move_up_down1" src="../../assets/team/bloc1.svg" alt="" draggable="false">
        </div>
        <div class="bloc1 move_up_down right_up">
          <img class="move_up_down4" src="../../assets/team/bloc1.svg" alt="" draggable="false">
        </div>
      </div>
      <div class="blocs bottom">
        <div class="bloc move_up_down">
          <img class="move_up_down2" src="../../assets/team/bloc1.svg" alt="" draggable="false">
        </div>
        <div class="bloc move_up_down">
          <img class="move_up_down3" src="../../assets/team/bloc1.svg" alt="" draggable="false">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}

</script>
<style scoped>

#container {
  position: absolute;
  max-width: 40vmin;
  margin: 0 auto;
  background-image: url(../../assets/team/eli.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 44vmin 44vmin;
  height: 50vmin;
  width: 40vmin;
}

.con {
  background-image: url(../../assets/team/shenq.svg);
  background-position: 6.5vmin 6.5vmin;
  background-repeat: no-repeat;
  background-size: 30vmin 30vmin;
  height: 40vmin;
  width: 100%;
}

.blocs {
  display: flex;
  justify-content: space-between;
  top: 7vmin;
  position: relative;
  cursor: pointer;
}

.bloc1 {
  max-width: 40vmin;
  background-image: url(../../assets/team/shoxq.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 7vmin;
  width: 10.5vmin;

}

.right_up {
  position: relative;
  left: 3vmin;
}

.bloc {
  max-width: 40vmin;
  margin: 0 auto;
  background-image: url(../../assets/team/shoxq.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 7vmin;
  width: 10.5vmin;
}

.bottom {
  margin-top: 23vmin
}

.move_up_down > img {
  width: 10vmin;
  position: relative;
  top: -3vmin;
}

.move_up_down1 {
  animation: lid-screen infinite 5.5s ease;
}

.move_up_down2 {
  animation: lid-screen infinite 4.5s ease;
}

.move_up_down3 {
  animation: lid-screen infinite 4s ease;
}

.move_up_down4 {
  animation: lid-screen infinite 3.5s ease;
}

@keyframes lid-screen {
  0% {
    top: -3vmin;
  }
  50% {
    top: 0;
  }
  100% {
    top: -3vmin;
  }
}

@media (max-width: 1199.98px) {
  #container {
    transform: scale(0.7);
  }
}

@media (max-width: 991.98px) {
  .con {
     transform: scale(1.35) ;
  }
}

@media (max-width: 767.98px) {
    .con {
        transform: scale(2) ;
    }
}
</style>

