<template>
  <section>
    <div class="container">
      <div class="process_section animate__animated animate__slow animate__fadeIn">
        <div class="process_section_container">
          <div class="heading_block">
            <div class="heading_row">
              <h2 class="heading"> {{ $t('working_process_title') }} </h2>
              <h2 class="heading_shadow"> {{ $t('working_process_letter') }} </h2>
            </div>
          </div>
          <div class="work_process_block">
            <vue-agile
                class="carousel"
                @after-change="afterSlide"
                :nav-buttons="false"
                :autoplay="true"
                :autoplay-speed="interval"
                :pause-on-hover="true"
            >
              <div class="slide" v-for="$i in this.$i18n.messages[this.$i18n.locale].working_process">
                <div class="progress_box">
                  <div class="icon">
                    <img :src="$i.img" draggable="false">
                  </div>
                  <h2 class="pro_title">{{ $i.title }}</h2>
                  <p class="pro_description">
                    {{ $i.description }}
                  </p>
                </div>
              </div>
            </vue-agile>
            <svg class="progress_circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387 530">
              <path fill="none" stroke="#dbdde1" stroke-width="2"
                    d="M4 29.8C39.4 12 79.4 2 121.7 2c145.3 0 263 117.8 263 263S267 528 121.7 528c-43.4 0-84.4-10.5-120.6-29.2">
              </path>
              <path class="progress_circle__fill" :class="{'pfill': fill}" ref="pfill" fill="none" stroke="url(#gradient)"
                    stroke-width="4"
                    d="M4 29.8C39.4 12 79.4 2 121.7 2c145.3 0 263 117.8 263 263S267 528 121.7 528c-43.4 0-84.4-10.5-120.6-29.2">
              </path>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#3DB7FF" />
                <stop offset="100%" stop-color="#144dc9" />
              </linearGradient>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import Buttons from "@/components/Buttons";
import {VueAgile} from 'vue-agile'

export default {
  name: "WorkingProcess",
  components: {Buttons, VueAgile},
  data() {
    return {
      fill: false,
      interval: 8000
    }
  },
  methods: {
    afterSlide: function () {
      this.fill = false
      setTimeout(function () {
        this.fill = true
      }.bind(this), 50)
    },
  },
}
</script>

<style lang="scss" scoped>

section {
  background-image: url('../../assets/svg/footer2.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

}

.process_section{
  opacity: 0;
}

.process_section.animate__fadeIn{
  opacity: 1;
}

.carousel {
  max-width: 40vw;
  padding: 2vmin 0 0;
  user-select: none;
}

.work_process_block {
  position: relative;
}

.process_section {
  display: flex;
  justify-content: center;
  padding-top: 2vmin;
  margin: 2vmin auto;

  .heading_block {
    margin-left: 0;

    .heading_row {
      .heading_shadow {
        text-align: left;
        background: -webkit-linear-gradient(var(--color9), var(--color2));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: 0.4;
        left: -3%;
      }
      .heading {
        text-align: left;
        color: var(--color5);
        text-shadow: 0 0 2px #fff;
      }
    }
  }
}

.heading_block {
  width: 100%;
  display: block;
  max-width: 700px;
  margin: 0 auto 20px;
  position: relative;

  .heading_row {
    position: relative;
    width: 100%;
    margin-bottom: 2vmin;

    .heading {
      width: 100%;
      text-align: center;
      color: #11192e;
      margin-bottom: 0;
      text-transform: uppercase;
      font-weight: 700 !important;
      font-size: 3vmin;
      position: relative;
      z-index: 1;
      white-space: nowrap;
    }

    .heading_shadow {
      position: absolute;
      top: -10%;
      left: -15%;
      width: 100%;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0;
      text-align: center;
      font-size: 5vmin;
      color: rgba(0, 0, 0, .08);
    }
  }
}

.progress_box {
  text-align: left;
  max-width: 30vw;
  margin-left: 3vmin;
  padding: 0 3vmin;
  width: 100%;

  .icon {
    width: 15vmin;
    height: 15vmin;
    margin-bottom: 2vmin;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .pro_title {
    color: var(--color5);
    transition: all 500ms ease;
    font-weight: 600;
    margin-bottom: 1vmin;
  }

  .pro_description {
    position: relative;
    color: var(--texts_color);
    transition: all 500ms ease;
    margin-bottom: 0;
  }
}

.progress_circle {
  position: absolute;
  top: -50%;
  right: -12%;
  display: block;
  height: 170%;
  width: auto;
  transform: rotate(-3deg);
}

// Circle animation classes

.progress_circle__fill {
  stroke-dasharray: 1073.67138671875;
  stroke-dashoffset: 1073.67138671875;
  transition: none;
}

.progress_circle__fill.pfill {
  stroke-dashoffset: 0;
  transition: all 8s linear;
}

:deep(.agile__dots) {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  position: absolute;
  bottom: 0;
  left: 0;

  .agile__dot button {
    position: relative;
    display: block;
    transform: scale(0.5295);
    width: 2vmin;
    height: 2vmin;
    background: #A5A8B5 !important;
    border: 4px solid transparent !important;
    border-radius: 50%;
    text-indent: -1000px;
    transition: all .3s ease-out
  }

  .agile__dot:not(:last-child) button {
    margin-bottom: 2vmin
  }

  .agile__dot.agile__dot--current button {
    transform: scale(1);
    background: #fff !important;
    border: 0.5vmin solid var(--color9) !important;
  }

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0.95vmin;
    display: block;
    width: 0.1vmin;
    height: calc(100% - 20px);
    background: #A5A8B5;
  }

  @media screen and (min-width: 768px) {
    .agile__dot:not(:last-child) {
      margin-bottom: 10px
    }
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
  .carousel {
    max-width: 55vw;
  }
  .progress_circle {
    position: absolute;
    top: -35%;
    right: -12%;
    display: block;
    height: 150%;
    width: auto;
    transform: rotate(-3deg);
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

  .progress_circle {
    opacity: 0.2;
  }

  section {
    padding-top: 5rem;
  }
  .carousel {
    max-width: 55vw;
    padding: 1rem 0;
  }

  .process_section {
    .heading_block {
      margin-left: 3rem;

      .heading_row {
        .heading {
          font-size: 2rem;
          white-space: initial;
          max-width: 70vw;
        }
        .heading_shadow {
          font-size: 3.5rem;
        }
      }
    }
  }

  .progress_box {
    max-width: unset;
  }
  .process_section {
    justify-content: flex-start;
    padding-left: 0;
  }

  :deep(.agile__dots) {
    display: none;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .carousel {
    max-width: 80vw;
  }
  .progress_circle {
    display: none;
  }

  .process_section {
    padding-left: 0;
    position: relative;

    .heading_block {
      margin-left: 2.2rem;
    }
    .heading_block {
      margin-bottom: 2rem;
      .heading_row {
        .heading {
          font-size: 1.8rem;
          white-space: initial
        }
        .heading_shadow {
          font-size: 3.5rem;
        }
      }
    }
  }

  .progress_box{
    margin-left: 0;
    .icon {
      width: 12rem;
      height:  12rem;
      position: absolute;
      top: 0;
      z-index: -1;
      opacity: 0.15;
      transform: translateX(120%);
    }
    .pro_title{
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }

  :deep(.agile__dots) {
    display: none;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .process_section {
    .heading_block {
      margin-left: 1rem;
      .heading_row {
        .heading {
          font-size: 1.2rem;
          white-space: pre-line;
        }
        .heading_shadow {
          font-size: 2.5rem;
          line-height: 2.5rem;
        }
      }
    }
  }

  .progress_box {
    .icon {
      width: 7rem;
      height: 7rem;
      transform: translateX(100%);
    }
  }
}

</style>